<template>
    <div class="container">
        <LogoFullSvg class="logo-icon" />
        <div class="card">
            <h1>404</h1>
            <p>{{ $t('common.404_pageTitle') }}:</p>
            <div class="links__wrapper">
                <div class="links__wrapper__inner">
                    <router-link :to="{ name: 'mealKits' }">{{ $t('navbar.mealKits') }}</router-link>
                    <router-link :to="{ name: 'weeksMenu' }">{{ $t('navbar.weeksMenu') }}</router-link>
                    <router-link :to="{ name: 'prices' }">{{ $t('navbar.prices') }}</router-link>
                </div>
                <div class="links__wrapper__inner">
                    <router-link :to="{ name: 'faq' }">{{ $t('navbar.faq') }}</router-link>
                    <router-link :to="{ name: 'contacts' }">{{ $t('navbar.contacts') }}</router-link>
                </div>
                <div class="links__wrapper__inner">
                    <router-link :to="{ name: 'blogArticles' }">{{ $t('navbar.blogArticles') }}</router-link>
                </div>
            </div>
        </div>
        <img src="@/assets/images/backgrounds/preview-section-bg.png" alt="preview-section-bg" class="image-top" />
        <img src="@/assets/images/backgrounds/auth-layout-bg.png" alt="auth-layout-bg" class="image-bottom" />
    </div>
</template>

<script>
    import LogoFullSvg from '@/assets/icons/navbar/logo-full.svg?inline';

    export default {
        name: 'NotFound',
        components: {
            LogoFullSvg,
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        position: relative;
        min-height: calc(100vh - 75px);
        overflow: hidden;

        @include column-align-center;

        .logo-icon {
            margin-top: 60px;
            margin-bottom: 30px;
        }

        .card {
            @include column-align-center;

            max-width: 470px;

            background: $white;
            box-shadow: $box-shadow-dark;

            border-radius: $br-10;

            padding: 24px;

            h1 {
                font-size: $font-100;
                color: $primary;

                margin-bottom: 10px;
            }

            p {
                text-align: center;

                margin-bottom: 24px;
            }

            .links__wrapper {
                @include column-align-center;

                &__inner {
                    @include row-align-center-justify-between;

                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    a {
                        font-weight: 700;
                        color: $primary;

                        text-decoration: underline;
                        text-align: center;

                        margin-right: 24px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .image-top,
        .image-bottom {
            z-index: -1;
            position: absolute;
        }

        .image-top {
            top: 0;
            right: 0;

            width: 261px;
            height: 540px;
        }

        .image-bottom {
            bottom: 0;
            left: 0;

            width: 329px;
            height: 553px;
        }
    }

    @include media($lg) {
        .container {
            .logo-icon {
                margin-top: 100px;
                margin-bottom: 90px;

                width: 400px;
                height: 80px;
            }

            .card {
                @include column-align-center;

                max-width: 470px;

                background: $white;
                box-shadow: $box-shadow-dark;

                border-radius: $br-10;

                padding: 24px;

                h1 {
                    font-size: $font-100;
                    color: $primary;

                    margin-bottom: 10px;
                }

                p {
                    text-align: center;

                    margin-bottom: 24px;
                }

                .links__wrapper {
                    @include column-align-center;

                    &__inner {
                        @include row-align-center-justify-between;

                        margin-bottom: 8px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            font-weight: 700;
                            color: $primary;

                            text-decoration: underline;
                            text-align: center;

                            margin-right: 24px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
